<template>
    <div>
        <b-alert :show="alert.show" :variant="alert.variant" fade>
      <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
        alert.text
      }}</span>
        </b-alert>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("MANUFACTURER.MANUFACTURERLIST")
          }}</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            $tc("MANUFACTURER.SHOW_COUNT", manufacturer.length, {
              total: manufacturer.length,
            })

          }}&nbsp;{{ manufacturer.length }} </span>

                </h3>
                <div class="card-toolbar">
                    <router-link :to="{ name: 'manufacturer', params: { id: 'new' } }">
                        <b-button variant="primary">{{ $t("MANUFACTURER.NEW") }}</b-button>
                    </router-link>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="manufacturer.length">
                    <table
                            class="table table-head-custom table-vertical-center"
                            id="kt_advance_table_widget_4"
                    >
                        <thead>
                        <tr class="text-left">

                            <th style="min-width: 160px">{{ $t("MANUFACTURER.NAME") }}</th>

                            <th style="min-width: 110px">{{ $t("MANUFACTURER.STREET") }}</th>
                            <th style="min-width: 120px">{{ $t("MANUFACTURER.CITY") }}</th>
                            <th style="min-width: 120px">{{ $t("MANUFACTURER.ZIP") }}</th>
                            <th style="min-width: 120px">{{ $t("MANUFACTURER.COUNTRY") }}</th>
                            <th style="min-width: 110px">{{ $t("MANUFACTURER.CODE") }}</th>
                            <th style="min-width: 110px">{{ $t("MANUFACTURER.COMMENT") }}</th>
                            <th class="pr-0 text-right" style="min-width: 160px">
                                {{ $t("ACTIONS") }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="manufacturer in paginatedManufacturer">
                            <tr v-bind:key="manufacturer.id">

                                <td>
                                    <router-link
                                            :to="{ name: 'manufacturer', params: { id: manufacturer.id } }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                    >{{ manufacturer.name }}
                                    </router-link
                                    >
                                </td>

                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ manufacturer.street }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ manufacturer.city }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ manufacturer.zip }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ manufacturer.country }}</span
                    >
                                </td>

                                   <td>
                                                    <span
                                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                                    >{{ manufacturer.code }}</span
                                                    >
                                                                </td>
   <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ manufacturer.comment }}</span
                    >
                                </td>

                                <td class="pr-0 text-left">
                                    <router-link
                                            :to="{
                        name: 'manufacturer',
                        params: { id: manufacturer.id, edit: true },
                      }"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                        <inline-svg
                                src="media/svg/icons/Communication/Write.svg"
                                :title="$t('EDIT')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </router-link>
                                    <a v-if="manufacturer.anz==0"
                                            v-on:click="confirmDelete(manufacturer.id)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                                :title="$t('DELETE')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </a>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <b-pagination
                            v-if="manufacturer.length > pagination.per_page"
                            class="pt-5"
                            v-model="pagination.current_page"
                            :total-rows="manufacturer.length"
                            :per-page="pagination.per_page"
                            align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import Swal from "sweetalert2";


    export default {
        name: "manufacturer",
        data() {
            return {
                manufacturer: [],
                alert: {
                    show: false,
                    message: "",
                    variant: "",
                },
                pagination: {
                    current_page: 1,
                    per_page: 20,
                },
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MANUFACTURER.MANUFACTURER")}]);
            ApiService.get("manufacturer")
                           .then(({data}) => {
                               this.manufacturer = data;
                           })
                           .catch(() => {
                               //Just keep it empty
                           });
        },
        methods: {
            confirmDelete: function (id) {
                const brand_index = this.manufacturer.findIndex((manufacturer) => manufacturer.id === id);
                const manufacturer = this.manufacturer.find((manufacturer) => manufacturer.id === id);
                Swal.fire({
                    title: this.$t("MANUFACTURER.DELETE_TITLE", {name: manufacturer.name}),
                    text: this.$t("MANUFACTURER.DELETE_TEXT"),
                    icon: "error",
                    showCancelButton: true,
                    cancelButtonText: this.$t("CANCEL"),
                    confirmButtonText: this.$t("DELETE"),
                    buttonsStyling: false,
                    customClass: {
                        cancelButton: "btn btn-secondary",
                        confirmButton: "btn btn-danger",
                    },
                    heightAuto: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.delete("manufacturer/" + id)
                            .then(() => {
                                this.manufacturer.splice(brand_index, 1);
                                this.showAlert(
                                    this.$t("MANUFACTURER.DELETE_SUCCESS", {name: manufacturer.name}),
                                    "success"
                                );
                            })
                            .catch(() => {
                                this.showAlert(
                                    this.$t("MANUFACTURER.DELETE_FAILURE", {name: manufacturer.name}),
                                    "danger"
                                );
                            });
                    }
                });
            },
            showAlert(text, variant) {
                this.alert.text = text;
                this.alert.variant = variant;
                this.alert.show = true;
                setTimeout(() => (this.alert.show = false), 5000);
            },
        },
        computed: {

            paginatedManufacturer() {
                const start =
                    (this.pagination.current_page - 1) * this.pagination.per_page,
                    end = start + this.pagination.per_page;
                return this.manufacturer.slice(start, end);
            },
        },
    };
</script>
